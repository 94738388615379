import {
  CheckIcon,
  ShoppingBagIcon,
  TruckIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import AppStorage from '@/utils/helpers';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { setOrderType } from '@/redux/slices/checkout.slice';

export default function OrderType() {
  const [active, setActive] = useState<number>(0);

  const dispatch = useAppDispatch();

  return (
    <div className="grid gap-3">
      {/* Order preference */}
      <p className="text-xs md:text-sm font-bold">
        How would you like to receive your order?
      </p>

      <div className="grid grid-cols-2 gap-10 justify-center md:justify-start">
        {/* Delivery */}
        <button
          className={`grid gap-2 justify-center shadow-lg text-crunchies-white bg-crunchies-red p-5 rounded-2xl relative`}
          onClick={() => {
            dispatch(setOrderType('delivery'));
            setActive(1);
          }}
        >
          <div
            className={`p-1 rounded-full text-crunchies-red absolute top-4 right-4 ${
              active === 1 ? ' bg-crunchies-white' : ' bg-crunchies-red'
            }`}
          >
            <CheckIcon className="w-4" />
          </div>
          <TruckIcon className="w-10 mx-auto" />
          <div className="font-bold uppercase">Delivery</div>
        </button>
        {/* Pickup */}
        <button
          className={`grid gap-2 justify-center shadow-lg text-crunchies-white bg-crunchies-red p-5 rounded-2xl relative`}
          onClick={() => {
            dispatch(setOrderType('pickup'));
            setActive(2);
          }}
        >
          <div
            className={`p-1 rounded-full text-crunchies-red absolute top-4 right-4 ${
              active === 2 ? ' bg-crunchies-white' : ' bg-crunchies-red'
            }`}
          >
            <CheckIcon className="w-4" />
          </div>
          <ShoppingBagIcon className="w-10 mx-auto" />
          <div className="font-bold uppercase">Pickup</div>
        </button>
      </div>
    </div>
  );
}

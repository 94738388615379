import { blogImg, careerImg, fanClubImg, offersImg } from '@/assets/images';
import Image from 'next/image';

export default function SitePages() {
  return (
    <section className="px-5 py-20 bg-crunchies-pink">
      {/* Menu content */}
      <div className="grid gap-10 md:grid-cols-2 w-full max-w-4xl mx-auto">
        <a href="/offers">
          <Image src={offersImg} alt="offers" />
        </a>
        <a href="/fan-club">
          <Image src={fanClubImg} alt="fan club" />
        </a>
        <a href="/careers">
          <Image src={careerImg} alt="career" />
        </a>
        <a href="/blog">
          <Image src={blogImg} alt="blog" />
        </a>
      </div>
    </section>
  );
}

import { appleStoreIcon, googlePlayIcon, mobileAppImg } from '@/assets/images';
import Image from 'next/image';

export default function MobileAppDownload() {
  return (
    <section className="px-5 bg-crunchies-red text-crunchies-white">
      <div className="w-full max-w-3xl mx-auto min-h-[400px] grid items-center md:grid-cols-2 gap-5 md:gap-10">
        {/* Mobile app image */}
        <div className="relative h-[400px] max-w-md">
          <Image
            src={mobileAppImg}
            className="object-cover object-right-top"
            alt="download our mobile app"
            fill
          />
        </div>

        {/* Download links */}
        <div className="grid gap-5 text-center md:text-left py-10">
          <h2>
            <div>Order on the Go!</div>
            <div className="bg-crunchies-red w-1/5 h-2 mx-auto rounded-full"></div>
          </h2>
          <p className="text-sm">
            Our mobile apps are now available on your device's app store
          </p>

          <div className="grid gap-5 w-max md:grid-cols-2 mx-auto md:mx-0">
            {/* Google Play */}
            <a href="https://play.google.com/store/apps/details?id=com.peachblossoms.crunchies.customers">
              <button className="bg-black py-1 px-2 rounded-xl grid text-right">
                <div className="text-xs">Android app on</div>
                <div className="flex gap-2 items-center">
                  <Image
                    src={googlePlayIcon}
                    alt="Google Play icon"
                    width={16}
                    height={16}
                  />
                  <div className="text-xl">Google Play</div>
                </div>
              </button>
            </a>

            {/* Apple Store */}
            <button className="bg-black py-1 px-2 rounded-xl grid text-left">
              <div className="text-xs">Download on the</div>
              <div className="flex gap-2 items-center">
                <Image
                  src={appleStoreIcon}
                  alt="Apple Store icon"
                  width={20}
                  height={20}
                />
                <div className="text-xl">App Store</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

import Image from 'next/image';
import OrderType from '../global/OrderType';
import { heroImg1, heroImg2, heroImg3 } from '@/assets/images';

export default function Hero() {
  return (
    <section className=" bg-crunchies-white text-crunchies-red px-5">
      <div className="w-full max-w-[1440px] mx-auto min-h-[400px] grid md:grid-cols-2 gap-5 items-end">
        {/* Text */}
        <div className="text-center md:text-left grid gap-10 py-5">
          <div className="grid gap-3">
            <h1 className="text-xl md:text-2xl">
              <span>Welcome to </span>
              <span className="font-extrabold uppercase">Crunchies!</span>
            </h1>
            <h3 className="text-5xl md:text-6xl lg:text-8xl">
              Taste the <br />
              Difference...
            </h3>
          </div>

          <a href="/menu" className="max-w-sm w-full mx-auto lg:mx-0">
            <OrderType />
          </a>
        </div>

        {/* Illustration or image */}
        <div className="relative h-[400px] lg:h-[600px]">
          <Image
            src={heroImg3}
            alt="happy cruncher"
            className="object-cover object-bottom"
            fill
          />
        </div>
      </div>
    </section>
  );
}

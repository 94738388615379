'use client';

import Hero from '@/components/home/Hero';
import DiscoverOurMenu from '@/components/home/DiscoverOurMenu';
import MobileAppDownload from '@/components/home/MobileAppDownload';
import SitePages from '@/components/home/SitePages';
import AboutUsSection from '@/components/home/AboutUsSection';

export default function HomePage() {
  return (
    <>
      {/* Hero section */}
      <Hero />

      {/* List menu by categories */}
      <DiscoverOurMenu />

      {/* Download mobile app */}
      <MobileAppDownload />

      {/* About Us section */}
      <AboutUsSection />

      {/* Links to important pages on the website */}
      <SitePages />
    </>
  );
}

import { aboutUsImg } from '@/assets/images';
import Image from 'next/image';
import { GiArmoredBoomerang } from 'react-icons/gi';

export default function AboutUsSection() {
  return (
    <section className="px-5 py-20 lg:py-40">
      <div className="w-full max-w-[1440px] mx-auto grid gap-10 md:gap-20">
        <h2 className="text-crunchies-red grid gap-3 text-center justify-center">
          <div>About Us</div>
          <div className="bg-crunchies-red w-1/5 h-2 mx-auto rounded-full"></div>
        </h2>
        {/* About Us content */}
        <div className="grid gap-10 md:grid-cols-2 w-full max-w-5xl mx-auto">
          {/* image */}
          <div className="bg-crunchies-pink relative h-[400px] rounded-xl overflow-hidden md:order-last">
            <Image
              src={aboutUsImg}
              alt="About Us"
              fill
              className="object-cover"
            />
          </div>

          {/* text */}
          <div className="grid gap-5 text-center md:text-left h-max">
            <p>
              Crunchies Fried Chicken is a first-grade quick service restaurant
              that offers a variety of quality fast food products and
              first-class services. We are currently spread across the
              South-South, South-East and South-West regions of Nigeria, with
              over 20 years of experience.
            </p>
            <p>
              We have carved the niche of excellence in the fast food market
              with a service per excellence acquired from interacting with our
              valued customers.
            </p>
            <a href="/about-us">
              <button className="flex gap-2 items-center bg-crunchies-red text-crunchies-white mx-auto md:ml-0 py-3 px-5 font-bold rounded-lg">
                <GiArmoredBoomerang />
                <div>Read More</div>
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
